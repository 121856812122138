import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '/src/assets/css/landing.css';
import '/src/components/shared.js'; 
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Isotope from 'isotope-layout'
import 'isotope-fit-columns'


/**
* Template Name: Dewi
* Updated: May 30 2023 with Bootstrap v5.3.0
* Template URL: https://bootstrapmade.com/dewi-free-multi-purpose-html-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
(function() {
    "use strict";
    // do_once:
    resize_screenshot()
    addEventListener("resize", () => {
      resize_screenshot()
    });
      
    function resize_screenshot() {
      var screenshot = document.getElementById('screenshot_image')
      if (!screenshot) return false;
      // console.log(event)
      // 3500x2300 are background dimensions
      var original_width = 3500
      var original_height = 2300
      var original_ratio = original_width / original_height
      var laptop_screen_x = 1226
      var laptop_screen_x_offset = 1114 
      var laptop_screen_y = 745
      var laptop_screen_y_offset = 408
      var initial_width = 100 * laptop_screen_x / original_width
      var initial_height = 100 * laptop_screen_y / original_height
      var relative_x_offset = 100 * laptop_screen_x_offset / original_width
      var relative_y_offset = 100 * laptop_screen_y_offset / original_width
      var current_ratio = document.body.clientWidth / window.innerHeight
      if (current_ratio > original_ratio ) {
        screenshot.classList.add("screenshot-scaling-y")
        screenshot.classList.remove("screenshot-scaling-x")
        screenshot.style.height= (initial_height * current_ratio / original_ratio) + '%'
        screenshot.style.width= initial_width + "%"
        screenshot.style.margin = `${relative_y_offset}% 0 0 ${relative_x_offset}%`
      }
      if (current_ratio < original_ratio) {
        screenshot.classList.add("screenshot-scaling-x")
        screenshot.classList.remove("screenshot-scaling-y")
        screenshot.style.height= initial_height+"%"
        screenshot.style.width= initial_width * original_ratio * 1 / current_ratio + '%'
        screenshot.style.margin = relative_y_offset * original_ratio / current_ratio  + "% 0 0 0"
      }
    }

    /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }
  
    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }
  
    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }
  
    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return
        let section = select(navbarlink.hash)
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)
  
    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header')
      let offset = header.offsetHeight
  
      if (!header.classList.contains('header-scrolled')) {
        offset -= 20
      }
  
      let elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }
  
    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }
  
    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }
  
    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function(e) {
      toggle_mobile_menu()
    })
    
    on('click', '#navbar-mob-bg', function(e) {
      console.log('clicked navbar bg')
      toggle_mobile_menu()
    })

    on('click', '#subscribe-to-newsletter', function(e) {
      console.log('subscribing to newsletter')  
      e.preventDefault(); // Prevent default form submission
        var url = '/newsletter-submit/';
        var method = 'POST';
        var form = document.getElementById('newsletter-subscription-form')
        var formData = new FormData(form);
        var xhr = new XMLHttpRequest();
        var resultMessage = document.getElementById('result-message');
        xhr.onreadystatechange = function() {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
              var response = JSON.parse(xhr.responseText);
              resultMessage.textContent = response.message;
              resultMessage.style.display = 'block';
            } else {
              resultMessage.textContent = 'Error occurred: ' + xhr.statusText;
              resultMessage.style.display = 'block';
            }
          }
        };
    
        xhr.open(method, url, true);
        xhr.setRequestHeader('X-CSRFToken', document.getElementsByName('csrfmiddlewaretoken')[0].value);
        xhr.send(formData);
      });

    function toggle_mobile_menu() {
      select('#navbar').classList.toggle('navbar-mobile')
      select('#navbar-mob-bg').classList.toggle('collapse')
      select('svg.fa-list').classList.toggle('collapse')
      select('svg.fa-xmark').classList.toggle('collapse')
    }
    /**
     * Mobile nav dropdowns activate
     */
    on('click', '.navbar .dropdown > a', function(e) {
      if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        this.nextElementSibling.classList.toggle('dropdown-active')
      }
    }, true)
  
    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
      if (select(this.hash)) {
        e.preventDefault()
  
        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile')
          let navbarToggle = select('.mobile-nav-toggle')
          navbarToggle.classList.toggle('bi-list')
          navbarToggle.classList.toggle('bi-x')
        }
        scrollto(this.hash)
      }
    }, true)
  
    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash)
        }
      }
    });
  
    /**
     * Preloader
     */
    let preloader = select('#preloader');
    if (preloader) {
      window.addEventListener('load', () => {
        preloader.remove()
      });
    }
  
    // /**
    //  * Initiate glightbox 
    //  */
    // const glightbox = GLightbox({
    //   selector: '.glightbox'
    // });
  
    /**
     * Testimonials slider
     */
    new Swiper('.testimonials-slider', {
      modules: [Navigation, Pagination],
      speed: 600,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      }
    });
   
    /**
     * Porfolio isotope and filter
     */
      let blogContainer = select('.blog-container');
      if (blogContainer) {
        var blogIsotope = new Isotope(blogContainer, {
          itemSelector: '.blog-item',
          layoutMode: 'masonry',
        });

        let blogFilters = select('#blog-flters li', true);
        
        on('click', '#blog-flters li', function(e) {
          e.preventDefault();
          blogFilters.forEach(function(el) {
            el.classList.remove('filter-active');
          });
          this.classList.add('filter-active');
  
          blogIsotope.arrange({
            filter: this.getAttribute('data-filter')
          });
  
        }, true);
      }
      // define an observer instance
      var observer = new IntersectionObserver(onIntersection, {
        root: null,   // default is the viewport
        threshold: .5 // percentage of target's visible area. Triggers "onIntersection"
      })

      // callback is called on intersection change
      function onIntersection(entries, opts){
        entries.forEach(entry => {
          if(entry.isIntersecting) {
              console.log('scrolled into view')
              blogIsotope.layout()
              observer.unobserve(entry.target)
            }
          }
        )
      }

      // Use the observer to observe an element
      if (document.querySelector('.blog-container')) {
        observer.observe( document.querySelector('.blog-container') )
      }

      // To stop observing:
  })()